import React from "react";

const ProductCard = ({ product }) => {
  return (
    <div className="product-card">
      <div className="product-image-container">
        <img
          src={product.image}
          alt={product.name}
          className="product-image"
        />
        <div className="product-name-overlay">
          {product.name}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
