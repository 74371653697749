import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";

import _map from 'lodash/map';

// Images
import UserImage from './assets/UserImage.jpeg';
import Product1 from './assets/Product1.jpeg';
import Product2 from './assets/Product2.jpeg';
import Product3 from './assets/Product3.jpeg';
import Result1 from './assets/Result1.jpeg';
import Result2 from './assets/Result2.jpeg';
import Result3 from './assets/Result3.jpeg';

// Sample list of images for the right section
const images = {
  1: { id: 1, product: Product1, result: Result1 },
  2: { id: 2, product: Product2, result: Result2 },
  3: { id: 3, product: Product3, result: Result3 },
};

function TryOnScreen({onComplete}) {
  const [imageId, setDraggedImageId] = useState(null); // Track the clicked image
  const [clickedImageId, setClickedImageId] = useState(null); // Track which image was clicked for animation
  const imageRefs = useRef([]); // Ref array for each image in the right section

  // Handle image click to set it as the result image
  const handleImageClick = (imageId) => {
    setDraggedImageId(imageId);
    setClickedImageId(imageId); // Set the clicked image for animation
  };

  // Recursive function to handle the sequence of clicks and scrolls
  const handleAutoActions = () => {
    const clickAndScroll = (index) => {
      if (index > Object.keys(images).length) return setTimeout(() => { onComplete() }, 2000); // Stop when all images are processed

      // Wait for 2 seconds before clicking
      setTimeout(() => {
        // Scroll to the image with a slower scroll
        imageRefs.current[index]?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });

        // Click the image after scrolling
        setTimeout(() => {
          imageRefs.current[index]?.click(); // Simulate the click
          
          // Proceed to the next image after 2 seconds
          clickAndScroll(index + 1);
        }, 500); // Wait 0.5 seconds before the click
      }, 2000); // Wait for 2 seconds before the scroll and click
    };

    // Start the recursive sequence from the first image
    clickAndScroll(0);
  };

  // Start auto actions when the component mounts
  useEffect(() => {
    handleAutoActions();
  }, []);

  return (
    <motion.div className="tryon-screen">
      {/* Top Text with Animation */}
      <motion.div
        className="top-text"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0.5 }}
      >
        <h2>...and our Virtual Try-On feature</h2>
      </motion.div>

      <motion.div
        className="tryon-screen-content"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.3 }}
      >
        {/* Left Section - User Image */}
        <motion.div
          className="left-section"
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
        >
          <img src={UserImage} alt="User" className="user-image" />
        </motion.div>

        {/* Center Section - White Plain Area */}
        <motion.div
          className="center-section"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
        >
          {/* Display the result image */}
          {imageId && (
            <motion.div
              className="result-container"
              initial={{ x: 0, opacity: 1 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <img
                src={images[imageId].result}
                alt="Dragged Result"
                className="result-image"
              />
            </motion.div>
          )}
        </motion.div>

        {/* Right Section - Scrollable List of Images */}
        <motion.div
          className="right-section"
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
        >
          <div className="scroll-images">
            {_map(images, (image, index) => (
              <motion.div
                key={index}
                ref={(el) => (imageRefs.current[index] = el)} // Assign refs to images
                onClick={() => handleImageClick(image.id)} // Set the image to be displayed in the center
                whileHover={{ scale: 1.1 }} // Add hover effect for interactivity
                whileTap={{ scale: 0.9 }} // Add click effect (scale down when clicked)
                className={`scroll-image-container ${clickedImageId === image.id ? "clicked" : ""}`} // Add the clicked class to the image
              >
                <img
                  src={image.product}
                  alt={`img-${index}`}
                  className="scroll-image"
                />
              </motion.div>
            ))}
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default TryOnScreen;
