import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import ProductCard from "./ProductCard"; // Import the ProductCard component
import Jacket1 from './assets/Jacket1.webp'
import Jacket2 from './assets/Jacket2.webp'
import Jacket3 from './assets/Jacket3.jpg'
import Jacket4 from './assets/Jacket4.webp'
import Jacket5 from './assets/Jacket5.jpg'
import Jacket6 from './assets/Jacket6.jpg'

const demoText = "Bike hoodies/jackets in black color with bike logo or pattern in red"; // New search text
const products = [
  {
    id: 1,
    name: "Bike = Life Jacket",
    image: Jacket1,
  },
  {
    id: 2,
    name: "Orange Star Jacket",
    image: Jacket2,
  },
  {
    id: 3,
    name: "Unisex Oversized Jacket",
    image: Jacket3,
  },
  {
    id: 4,
    name: "Leather Jacket",
    image: Jacket4,
  },
  {
    id: 5,
    name: "Biking Jacket 69",
    image: Jacket5,
  },
  {
    id: 6,
    name: "Bullet Rider Comfort Jacket",
    image: Jacket6,
  },
];

function SearchScreen({onComplete}) {
  const [typedText, setTypedText] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false); // Track the button click state

  useEffect(() => {
    const timer = setTimeout(onComplete, demoText.length * 50 + 1000 + 4000 + 3000);
    return () => clearTimeout(timer);
  }, [onComplete]);

  useEffect(() => {
    const typeText = async () => {
      for (let i = 0; i <= demoText.length; i++) {
        setTypedText(demoText.slice(0, i));
        await new Promise((resolve) => setTimeout(resolve, 50));
      }
      setTimeout(handleSearchClick, 1000); // Simulate button click after typing ends
    };
    typeText();
  }, []);

  const handleSearchClick = () => {
    setButtonClicked(true); // Trigger button click animation
    setTimeout(() => {
      setButtonClicked(false); // Reset button animation after delay
    }, 300); // Matches the duration of button shrink animation
    setTimeout(() => {
      setShowResults(true); // Show results after button click animation
    }, 500); // Delay results to sync with button animation
  };

  return (
    <div className="search-screen">
      <div className="page-heading">
        <h2>Experience our conversational search</h2>
      </div>

      <div className="search-container">
        <motion.input
          type="text"
          className="search-input"
          value={typedText}
          placeholder="Search..."
          readOnly
          initial={{ opacity: 0, y: -20 }}  // Starting point: offscreen (above)
          animate={{ opacity: 1, y: 0 }}    // Ending point: fully visible
          transition={{ duration: 1 }}      // Smooth transition
        />
        <motion.button
          className="search-button"
          onClick={handleSearchClick}
          animate={{
            scale: buttonClicked ? 0.8 : 1, // Shrink the button when clicked
            boxShadow: buttonClicked
              ? "0 0 10px rgba(0, 123, 255, 0.7)"
              : "0 0 20px rgba(0, 123, 255, 0.5)",
          }}
          transition={{
            type: "spring",
            stiffness: 300,
            damping: 20,
          }}
        >
          Search
        </motion.button>
      </div>

      <motion.div className="results-grid">
        {showResults &&
          products.slice(0, 6).map((product, index) => (
            <motion.div
              key={product.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.5, duration: 0.5 }}
            >
              <ProductCard product={product} />
            </motion.div>
          ))}
      </motion.div>
    </div>
  );
}

export default SearchScreen;
