import React, { useEffect } from "react";
import { motion } from "framer-motion";

const text = "Welcome to YourFash.ai";
const subtext = "where style meets innovation";

function IntroScreen({ onComplete }) {
  useEffect(() => {
    const timer = setTimeout(onComplete, text.length * 100 + 3000);
    return () => clearTimeout(timer);
  }, [onComplete]);

  return (
    <motion.div
      className="intro-screen"
      initial={{ opacity: 1 }}
      animate={{ opacity: 0 }}
      exit={{ opacity: 0 }}  // Add exit transition when the screen leaves
      transition={{ delay: text.length * 0.1 + 3, duration: 1 }}
    >
      <motion.div className="intro-text-container">
        <h1 className="intro-text">
          {text.split("").map((char, index) => (
            <motion.span
              key={index}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: index * 0.1 }}
            >
              {char}
            </motion.span>
          ))}
        </h1>
        <motion.p
          className="intro-subtext"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: text.length * 0.1 + 0.5, duration: 1 }}
        >
          {subtext}
        </motion.p>
      </motion.div>
    </motion.div>
  );
}

export default IntroScreen;
