import React, { useState } from "react";
import IntroScreen from "./IntroScreen";
import SearchScreen from "./SearchScreen";
import TryOnScreen from "./TryOnScreen";
import "./App.css";

function App() {
  const [showSearchScreen, setShowSearchScreen] = useState(false);
  const [showTryOnScreen, setShowTryOnScreen] = useState(false);

  const handleTransitionToSearchScreen = () => {
    setShowSearchScreen(true);
  };

  const handleTransitionToTryOnScreen = () => {
    setShowSearchScreen(false);
    setShowTryOnScreen(true);
  };

  const handleTransitionToIntroScreen = () => {
    setShowSearchScreen(false);
    setShowTryOnScreen(false);
  };

  return (
    <div className="App">
      <>
      {!showSearchScreen && !showTryOnScreen && <IntroScreen onComplete={handleTransitionToSearchScreen} />}
      {showSearchScreen && <SearchScreen onComplete={handleTransitionToTryOnScreen} />}
      {showTryOnScreen && <TryOnScreen onComplete={handleTransitionToIntroScreen} />}
      </>
    </div>
  );
}

export default App;
